<template>
  <a-dims-search-bar
    placeholder="Buscar notificación"
    hide-search
    @search="fetch({ search: $event })">

    <div class="uppercase space-x-2">
      <h1 class="text-black py-4">
        Notificaciones
      </h1>
    </div>

    <template #results>
      <div class="flex justify-center items-center flex-col" v-if="loading">
        <a-loader color="text-gray" class="h-10 w-10" />
        <h1 class="text-2xl text-white">Buscando</h1>
      </div>
    </template>
  </a-dims-search-bar>

  <a-list
    class="py-16"
    :source="pagination.data"
    :loading="loading"
    :should-emit-bottom-reached="pagination.next_page_url !== null"
    @bottom-reached="fetch({ page: pagination.next_page_url ? pagination.current_page + 1 : pagination.current_page }, true)">
    <template #default="{ item }">
      <component :notification="item" :is="component(item)" />
    </template>
  </a-list>
</template>

<script>
import FoodieFavouredCell from './cells/foodie-favoured-cell'
import PrivateCollabInvitationCell from './cells/private-collab-invitation-cell'
import FallbackCell from './cells/fallback-cell'
import RestaurantCanceledCollabCell from './cells/restaurant-canceled-collab-cell'
import FoodieRatedCell from './cells/foodie-rated'
import ConfirmedCollabFoodieCell from './cells/confirmed-collab-foodie-cell'
import SharedPostReminderCell from './cells/shared-post-reminder'
// import { mapActions } from 'vuex'

export default {
  components: {
    FallbackCell, // eslint-disable-line vue/no-unused-components
    FoodieFavouredCell, // eslint-disable-line vue/no-unused-components
    PrivateCollabInvitationCell, // eslint-disable-line vue/no-unused-components
    RestaurantCanceledCollabCell, // eslint-disable-line vue/no-unused-components
    FoodieRatedCell, // eslint-disable-line vue/no-unused-components
    ConfirmedCollabFoodieCell, // eslint-disable-line vue/no-unused-components
    SharedPostReminderCell // eslint-disable-line vue/no-unused-components
  },
  data: () => ({
    pagination: { data: [] },
    loading: false,
    query: []
  }),
  methods: {
    // ...mapActions({
    //   read: 'notifications/read'
    // }),
    read () {
      this.$repository.notifications
        .read(this.notifications)
        .catch((error) => (console.log(error)))
    },
    toKebabCase (string) {
      const regex = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      return string.match(regex)
        .map($0 => $0.toLowerCase())
        .join('-')
    },
    component (notification) {
      const type = this.toKebabCase(notification.type)

      const types = [
        'private-collab-invitation',
        'foodie-favoured',
        'restaurant-canceled-collab',
        'foodie-rated',
        'confirmed-collab-foodie',
        'shared-post-reminder'
      ]

      if (types.includes(type)) {
        return `${type}-cell`
      }

      return 'fallback-cell'
    },
    fetch (query = {}, appending = false) {
      this.loading = !this.loading
      this.$repository.notifications
        .index(query)
        .then(({ data: pagination }) => {
          this.pagination = appending
            ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
            : pagination
        })
        .finally(() => (this.loading = !this.loading))
    },
    readNotifications () {
      this.read()
    }
  },
  mounted () {
    this.fetch(this.query)
    this.readNotifications()
    window.scrollTo({ top: 0 })
  }
}
</script>
