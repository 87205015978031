<template>
  <li class="py-2 flex space-x-2 px-4 select-none">
    <div class="flex-shrink-0">
      <a-initials :name="notification?.data?.restaurant?.name" :src="notification?.data?.restaurant?.profile_picture" :desing-class="'w-12 h-12'" />
    </div>
    <div @click="handleRedirect" class="flex-grow flex pb-1">
      <div class="text-left">
        <b>{{ notification?.data?.restaurant?.ig_username ? '@' + notification?.data?.restaurant?.ig_username : notification?.data?.restaurant?.name }}</b> te ha incluido en sus favoritos.
      </div>
    </div>
    <div class="flex-auto text-right">
      <p>{{ notificationTime }}</p>
    </div>
  </li>
</template>

<script>
import { mapActions } from 'vuex'
import Moment from 'moment'

export default {
  props: {
    notification: { required: true, type: Object }
  },
  computed: {
    notificationTime () {
      const today = Moment(new Date().toISOString())
      const notifyCreation = Moment(this.notification?.created_at)
      const diference = today.diff(notifyCreation, 'minutes')
      // const hours = today.diff(notifyCreation, 'h')
      // const lala = Math.floor(diference / 1440)

      const numdays = Math.floor(diference / 1440)
      const numhours = Math.floor((diference % 1440) / 60)
      const numminutes = Math.floor((diference % 1440) % 60)
      let date = null
      if (numdays > 0) {
        date = numdays + 'd'
      } else if (numhours > 0) {
        date = numhours + 'h'
      } else if (numminutes > 0) {
        date = numminutes + 'm'
      } else {
        date = 0 + 'm'
      }
      // return numdays + ' dia(s) ' + numhours + 'h ' + numminutes + 'm' + date
      return date
    }
  },
  methods: {
    ...mapActions({
      read: 'notifications/readSingle'
    }),
    handleRedirect () {
      const notifications = []
      notifications.push(this.notification?.id)
      // this.read(notifications)
      this.$router.push({ path: `/restaurants/${this.notification?.data?.restaurant?.id}` })
    }
  }
}
</script>
