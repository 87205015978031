<template>
  <div class="flex items-center text-left p-4 transition-all duration-100"
       :class="selected && multi ? 'bg-selected' : 'bg-unselected' ">
    <div class="flex-none cursor-pointer" >
      <div class="grid-thumb-image flex-initial align-middle left-0">
<!--        <div v-if="value.data.foodie.profile_picture">-->
<!--          <img :src="value.data.foodie.profile_picture" alt="foodie"/>-->
<!--        </div>-->
<!--        <div v-else>-->
<!--          <img src="../../../assets/logo.png" alt="foodie"/>-->
<!--        </div>-->
        <a-initials :name="value?.data?.foodie?.name" :src="value?.data?.foodie?.profile_picture" :desing-class="'w-12 h-12'" />
      </div>
    </div>
    <div class="flex-auto font-light pl-small cursor-pointer">
      <div @click="handleRedirect" v-if="value.type === 'accepted_collab'" class="cursor-pointer">
<!--        <p class="font-bold inline-block">{{ '@' + value.data.foodie.ig_username }}&nbsp;</p>-->
        <p class="font-bold inline-block">
          {{ `${value?.data?.foodie?.ig_username !== null
          ? '@' + value?.data?.foodie?.ig_username
          : value?.data?.foodie?.name}` }}&nbsp;</p>
        <p class="inline-block">ha <span class="text-green-500 font-bold">aceptado</span> tu colaboración</p>
      </div>
<!--      <div v-if="value.type === 'pending_confirm_collab'">-->
<!--        <p class="font-bold inline-block">{{ '@' + value.data.foodie.ig_username }}</p>-->
<!--        <p class="inline-block">&nbsp;ha aceptado tu colaboración pero necesita ser confirmado</p>-->
<!--      </div>-->
      <div @click="handleRedirect" v-if="value?.type === 'canceled_collab'" class="cursor-pointer">
        <p class="font-bold inline-block">
          {{ `${value?.data?.foodie?.ig_username !== null
          ? '@' + value?.data?.foodie?.ig_username
          : value?.data?.foodie?.name}` }}&nbsp;</p>
        <p class="inline-block">ha <span class="text-red font-bold">cancelado</span> tu colaboración</p>
      </div>
      <div @click="handleRedirect" v-if="value?.type === 'ignored_collab_foodie'" class="cursor-pointer">
        <p class="font-bold inline-block">
          {{ `${value?.data?.foodie?.ig_username !== null
          ? '@' + value?.data?.foodie?.ig_username
          : value?.data?.foodie?.name}` }}&nbsp;</p>
        <p class="inline-block">ha <span class="text-red font-bold">rechazado</span> tu colaboración</p>
      </div>
      <div @click="handleRedirect" v-if="value?.type === 'foodie_interested'" class="cursor-pointer">
        <p class="font-bold inline-block">
          {{ `${value?.data?.foodie?.ig_username !== null
          ? '@' + value?.data?.foodie?.ig_username
          : value?.data?.foodie?.name}` }}&nbsp;</p>
        <p class="inline-block">está <span class="text-primary font-bold">interesado</span> en colaborar</p>
      </div>
      <div @click="handleRedirect" v-if="value?.type === 'public_collab_filled'" class="cursor-pointer">
        <p class="font-bold inline-block">
          {{ `${value?.data?.foodie?.ig_username !== null
          ? '@' + value?.data?.foodie?.ig_username
          : value?.data?.foodie?.name}` }}&nbsp;</p>
        <p class="inline-block">ha <span class="text-green-500 font-bold">aceptado</span> tu invitacion abierta</p>
      </div>
      <div v-if="value?.type === 'restaurant_rated'" class="cursor-pointer">
        <p class="font-bold inline-block">
          {{ `${value?.data?.foodie?.ig_username !== null
            ? '@' + value?.data?.foodie?.ig_username
            : value?.data?.foodie?.name}` }}&nbsp;</p>
        <p class="inline-block">te ha <span class="text-primary font-bold">valorado</span></p>
      </div>
      <div v-if="value?.type === 'rate_foodie_reminder'">
        <p class="inline-block">Recuerda valorar a&nbsp;</p>
        <p class="font-bold inline-block">
          {{ `${value?.data?.foodie?.ig_username !== null
          ? '@' + value?.data?.foodie?.ig_username
          : value?.data?.foodie?.name}` }}&nbsp;</p>
      </div>
      <div @click="handleRedirect" v-if="value.type === 'pending_confirm_collab'">
        <p class="font-bold inline-block">
          {{ `${value?.data?.foodie?.ig_username !== null
            ? '@' + value?.data?.foodie?.ig_username
            : value?.data?.foodie?.name}` }}&nbsp;</p>
        <p class="inline-block">desea <span class="text-primary font-bold">unirse</span> a una colaboración</p>
      </div>
      <div @click="handleRedirect" v-if="value.type === 'foodie_applied_to_public_collab'">
        <p class="font-bold inline-block">
          {{ `${value?.data?.foodie?.ig_username !== null
            ? '@' + value?.data?.foodie?.ig_username
            : value?.data?.foodie?.name}` }}&nbsp;</p>
        <p class="inline-block">desea <span class="text-primary font-bold">unirse</span> a una colaboración públicar</p>
      </div>
    </div>
    <div class="flex-none flex items-center">
<!--      <div v-if="value.type === 'foodie_interested'" class="flex-none">-->
<!--        <button class="grid-button bg-primary text-sm text-white uppercase">Colab</button>-->
<!--      </div>-->
      <div class="flex-col flex text-right" :class="value?.type === 'rate_foodie_reminder' ? 'mx-2' : ''">
        <p class="flex-1">{{ notificationTime }}</p>
      </div>
      <div v-if="value?.type === 'rate_foodie_reminder'" class="flex-col flex">
        <p @click="rate = true" class="cursor-pointer rounded-full bg-primary text-center text-white text-sm px-4 py-2">Valorar</p>
      </div>
<!--      <div v-if="value.type === 'rate_foodie_reminder'" class="flex-none">-->
<!--        <i @click="handleRate" class="icon-thumbsUp inline-block p-2 border rounded-full border-gray "></i>-->
<!--        <i @click="handleRate" class="icon-thumbsDown inline-block p-2 border rounded-full border-gray"></i>-->
<!--      </div>-->
    </div>
  </div>
  <a-alert :show="rate" @cancel="rate = false"
           @confirm="submitRate"
           :confirm-text="'Enviar'"
           :cancel-text="'Cancelar'"
           title="Valorar Foodie">
    <Rating v-model="rating.rate" :stars="5" :cancel="false" class="text-center w-full mt-5 mx-auto"/>
    <textarea v-model="rating.comment" placeholder="Comentario (opcional)" class="mt-normal w-full p-2 border rounded-md" rows="5"></textarea>
    <div class="text-center custom-max-w mx-auto mt-2">
      <div class="space-x-2 text-sm">
        <p>
          Al hacer una reseña aceptas que SoloFoodies trate tus datos según nuestra Política de Privacidad. Los recogemos para facilitar la publicación, gestión y control de las opiniones de los usuarios. No compartiremos tus datos con terceros, salvo obligación legal. Puedes ejercer tus derechos de acceso, rectificación, supresión y oposición, entre otros, según nuestra Política de Privacidad.
        </p>
      </div>
    </div>
    <!--    <Textarea v-model="rating.comment" rows="5" cols="30" :autoResize="true"/>-->
  </a-alert>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import Rating from 'primevue/rating'
import Moment from 'moment'

export default {
  name: 'GridElement',
  components: {
    Rating
  },
  props: {
    value: Object,
    gridType: String,
    multi: Boolean
  },
  data () {
    return {
      code: '',
      rating: {
        rate: 5,
        comment: ''
      },
      selected: false,
      rate: false,
      terms: false
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    }),
    notificationTime () {
      const today = Moment(new Date().toISOString())
      const notifyCreation = Moment(this.value?.created_at)
      const diference = today.diff(notifyCreation, 'minutes')
      const numdays = Math.floor(diference / 1440)
      const numhours = Math.floor((diference % 1440) / 60)
      const numminutes = Math.floor((diference % 1440) % 60)
      let date = null

      if (numdays > 0) {
        date = numdays + 'd'
      } else if (numhours > 0) {
        date = numhours + 'h'
      } else if (numminutes > 0) {
        date = numminutes + 'm'
      } else {
        date = 0 + 'm'
      }
      return date
    }
  },
  methods: {
    ...mapActions({
      read: 'notifications/readSingle'
    }),
    handleRedirect () {
      const notifications = []
      notifications.push(this.value.id)
      // this.read(notifications)
      if ((this.value.type === 'foodie_interested')) {
        this.$router.push({ path: `/explore/${this.value.data.foodie.id}` })
      } else {
        this.$router.push({ path: `/collaborations/${this.value.data.collaboration.id}` })
      }
    },
    submitRate () {
      this.$repository.collaborations
        .collaboration(this.value.data.collaboration.id)
        .rateFoodie(this.value.data.foodie.id, { rate: this.rating.rate, comment: this.rating.comment })
        .finally(() => { this.rate = !this.rate })
        .catch(error => (console.log(error)))
    }
  }
}
</script>

<style scoped>

img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}
.icon-heartEmpty{
  color: #00000017;
  font-size: 18px;
}
.icon-heart{
  color: #FE4242;
  font-size: 18px;
}
.selected{
  @apply bg-primary text-white
}
.unselected{
  @apply bg-white border-gray text-white
}
.bg-selected{
  background-color: rgba(242, 180, 21, 0.1);
}
.bg-unselected {
  background-color: #ffffff;
}
.favorite {
  @apply text-red
}
.unfavorite {
  @apply text-gray
}
.testing-transition{
  transition: all 4s ease-in;
}
.p-rating .p-rating-icon.pi-star{
  color: #e9ff34 !important;
}
.p-rating-icon{
  outline: none;
}
</style>
