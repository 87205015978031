<template>
  <li>
    <router-link :to="{ name: 'collaborations.show', params: { id: notification?.data?.collaboration?.id } }" class="py-2 items-center flex space-x-2 px-4 select-none">
      <div class="flex-shrink-0">
        <a-initials :name="notification?.data?.restaurant?.name" :src="notification?.data?.restaurant?.profile_picture" :desing-class="'w-12 h-12'" />
      </div>
      <div class="flex-grow flex pb-1">
        <div class="text-left">
          <span>
            No has ingresado los enlaces de tus publicaciones sobre la colaboración <b>#{{ notification?.data?.collaboration?.id }}</b>.
          </span>
        </div>
      </div>
      <div class="flex-auto text-right">
        <p>{{ notificationTime }}</p>
      </div>
    </router-link>
  </li>
</template>

<script>
import { defineComponent } from 'vue'
import Moment from 'moment'

export default defineComponent({
  props: {
    notification: { required: true, type: Object }
  },
  computed: {
    notificationTime () {
      const today = Moment(new Date().toISOString())
      const notifyCreation = Moment(this.notification?.created_at)
      const diference = today.diff(notifyCreation, 'minutes')
      // const hours = today.diff(notifyCreation, 'h')
      // const lala = Math.floor(diference / 1440)

      const numdays = Math.floor(diference / 1440)
      const numhours = Math.floor((diference % 1440) / 60)
      const numminutes = Math.floor((diference % 1440) % 60)
      let date = null
      if (numdays > 0) {
        date = numdays + 'd'
      } else if (numhours > 0) {
        date = numhours + 'h'
      } else if (numminutes > 0) {
        date = numminutes + 'm'
      } else {
        date = 0 + 'm'
      }
      // return numdays + ' dia(s) ' + numhours + 'h ' + numminutes + 'm' + date
      return date
    }
  }
})
</script>
