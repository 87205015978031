<template>
  <Header :header="header"></Header>
<!--  <div class="flex grid-header">-->
<!--    <div class="flex-grow">-->
<!--      <p class="text-left text-gray uppercase">populares</p>-->
<!--    </div>-->
<!--    &lt;!&ndash;    <div class="flex-none">&ndash;&gt;-->
<!--    &lt;!&ndash;      <p class="text-right text-gray"><i class="icon-sort text-lg"></i></p>&ndash;&gt;-->
<!--    &lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
  <div class="gcp-restaurant-explore">
    <a-list
      class="flex flex-col"
      :source="pagination.data"
      :loading="loading"
      :should-emit-bottom-reached="pagination.next_page_url !== null"
      @bottom-reached="fetch({ page: pagination.next_page_url ? pagination.current_page + 1 : pagination.current_page }, true)"
      #default="{ item }">
      <GridElement :value="item"/>
    </a-list>
  </div>
</template>

<script>
import Header from '@/components/authenticated/Header'
import { mapGetters, mapState } from 'vuex'
import GridElement from '@/components/authenticated/notifications/GridElement'

export default {
  name: 'Notifications',
  components: {
    Header,
    GridElement
  },
  data () {
    return {
      header: {
        type: 'notifications'
      },
      pagination: { data: [] },
      modal: null,
      loading: false,
      query: {
        read: false
      },
      notifications: []
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  methods: {
    // ...mapActions({
    //   read: 'notifications/read'
    // }),
    read () {
      this.$repository.notifications
        .read(this.notifications)
        .catch((error) => (console.log(error)))
    },
    update (collaboration, index) {
      this.pagination = {
        ...this.pagination,
        data: this.pagination.data
          .map(($0, $1) => $1 === index ? { ...$0, ...collaboration } : $0)
      }
    },
    fetch (query = {}, appending) {
      this.loading = !this.loading
      this.$repository.notifications
        .index({ limit: 15, ...query })
        .then(({ data: pagination }) => {
          this.pagination = appending
            ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
            : pagination
        })
        .finally(() => (this.loading = !this.loading))
    },
    readNotifications () {
      // console.log(this.$store.state.notifyValues)
      this.read()
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.fetch()
      this.readNotifications()
      window.scrollTo({ top: 0 })
    }
  }
}
</script>

<style scoped>
.gcp-restaurant-explore{
  padding-top: 56px;
}
@media screen and (min-width: 1000px){
  .gcp-restaurant-explore{
    padding-top: 66px;
  }
}
</style>
