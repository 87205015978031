<template>
  <li class="py-2 flex space-x-2 px-4 select-none items-center">
    <div class="flex-shrink-0">
<!--      <img v-if="notification?.data?.restaurant?.profile_picture" :src="notification?.data?.restaurant?.profile_picture" class="h-12 w-12 bg-white object-cover rounded-full text-white flex items-center justify-center" />-->
<!--      <img v-else src="../../../../../assets/logo.png" class="h-12 w-12 rounded-full text-white flex items-center justify-center object-cover" />-->
      <a-initials :name="notification?.data?.restaurant?.name" :src="notification?.data?.restaurant?.profile_picture" :desing-class="'w-12 h-12'" />
    </div>
    <div @click="handleRedirect" class="flex-grow flex pb-1">
      <p class="text-left">
<!--        <b>@{{ notification?.data?.restaurant?.ig_username.replace('@', '').trim() ?? notification?.data?.restaurant?.ig_username.replace('@', '').trim() }}</b> te ha valorado-->
        <b>{{ notification?.data?.restaurant?.ig_username ? '@' + notification?.data?.restaurant?.ig_username : notification?.data?.restaurant?.name }}</b> te ha valorado
      </p>
    </div>
    <div class="flex-auto text-right">
      <p>{{ notificationTime }}</p>
    </div>
  </li>
</template>

<script>
import { mapActions } from 'vuex'
import Moment from 'moment'

export default {
  props: {
    notification: { required: true, type: Object }
  },
  computed: {
    notificationTime () {
      const today = Moment(new Date().toISOString())
      const notifyCreation = Moment(this.notification?.created_at)
      const diference = today.diff(notifyCreation, 'minutes')
      // const hours = today.diff(notifyCreation, 'h')
      // const lala = Math.floor(diference / 1440)

      const numdays = Math.floor(diference / 1440)
      const numhours = Math.floor((diference % 1440) / 60)
      const numminutes = Math.floor((diference % 1440) % 60)
      let date = null
      if (numdays > 0) {
        date = numdays + 'd'
      } else if (numhours > 0) {
        date = numhours + 'h'
      } else if (numminutes > 0) {
        date = numminutes + 'm'
      } else {
        date = 0 + 'm'
      }
      // return numdays + ' dia(s) ' + numhours + 'h ' + numminutes + 'm' + date
      return date
    }
  },
  methods: {
    ...mapActions({
      read: 'notifications/readSingle'
    }),
    handleRedirect () {
      const notifications = []
      notifications.push(this.notification?.id)
      // this.read(notifications)
      this.$router.push({ path: `/collaborations/${this.notification?.data?.collaboration?.id}` })
    }
  }
}
</script>
