<template>
  <li class="py-2 flex space-x-2 px-4 select-none">
    <div class="flex-shrink-0">
<!--      <img v-if="notification?.data?.restaurant?.profile_picture" :src="notification?.data?.restaurant?.profile_picture" class="h-12 w-12 bg-white object-cover rounded-full text-white flex items-center justify-center" />-->
<!--      <img v-else src="../../../../../assets/logo.png" class="h-12 w-12 rounded-full text-white flex items-center justify-center object-cover" />-->
      <a-initials :name="notification?.data?.restaurant?.name" :src="notification?.data?.restaurant?.profile_picture" :desing-class="'w-12 h-12'" />
    </div>
    <div class="flex-grow flex pb-1">
      <div class="flex-grow flex flex-col items-start">
        <p class="text-left">
<!--          <b>@{{ notification?.data?.restaurant?.ig_username.replace('@', '').trim() ?? notification?.data?.restaurant?.ig_username.replace('@', '').trim() }}</b> ha cancelado la colaboración-->
          <b>{{ notification?.data?.restaurant?.ig_username ? '@' + notification?.data?.restaurant?.ig_username : notification?.data?.restaurant?.name }}</b> ha cancelado la colaboración
        </p>
      </div>
<!--      <div class="flex-shrink-0">-->
<!--        <router-link :to="{ name: 'collaborations.show', params: { id: notification.data?.collaboration?.id } }" class="rounded-full focus:outline-none bg-primary text-white px-2 text-sm py-2">-->
<!--          VER COLAB-->
<!--        </router-link>-->
<!--      </div>-->
    </div>
    <div class="flex-auto text-right">
      <p>{{ notificationTime }}</p>
    </div>
  </li>
</template>

<script>
import Moment from 'moment'

export default {
  props: {
    notification: { required: true, type: Object }
  },
  computed: {
    notificationTime () {
      const today = Moment(new Date().toISOString())
      const notifyCreation = Moment(this.notification?.created_at)
      const diference = today.diff(notifyCreation, 'minutes')
      // const hours = today.diff(notifyCreation, 'h')
      // const lala = Math.floor(diference / 1440)

      const numdays = Math.floor(diference / 1440)
      const numhours = Math.floor((diference % 1440) / 60)
      const numminutes = Math.floor((diference % 1440) % 60)
      let date = null
      if (numdays > 0) {
        date = numdays + 'd'
      } else if (numhours > 0) {
        date = numhours + 'h'
      } else if (numminutes > 0) {
        date = numminutes + 'm'
      } else {
        date = 0 + 'm'
      }
      // return numdays + ' dia(s) ' + numhours + 'h ' + numminutes + 'm' + date
      return date
    }
  }
}
</script>
