<template>
  <div>
    <component :is="`notifications-${user.isFoodie ? 'foodie' : 'restaurant'}`"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import notificationsRestaurant from '@/views/authenticated/restaurants/Notifications'
import notificationsFoodie from '@/views/authenticated/foodies/notifications/index.vue'

export default {
  name: 'Notifications',
  components: {
    notificationsRestaurant,
    notificationsFoodie
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  }
}
</script>

<style scoped>

</style>
