<template>
  <div class="flex justify-center items-center bg-gray-200 m-1 p-4 font-semibold rounded-lg mt-0">
    Notificacion fuera de formato {{`{${notification.type}\}`}}
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    notification: { required: true, type: Object }
  }
})
</script>
